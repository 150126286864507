const actions = {
    omsTokenRz(context, payload) {
        context.commit('omsTokenRz', payload)
    },
    userInfo(context, payload) {
        context.commit('userInfo', payload)
    },
    RoleRights(context, payload) {
        context.commit('RoleRights', payload)
    },
    projectPerimission(context, payload) {
        context.commit('projectPerimission', payload)
    },
    projectGlobal(context, payload) {
        context.commit('projectGlobal', payload)
    },
    projectRight(context, payload) {
        context.commit('projectRight', payload)
    },
    project_menu(context, payload) {
        context.commit('project_menu', payload)
    },
    Menus(context, payload) {
        context.commit('Menus', payload)
    },
    departmentList(context, payload) {
        context.commit('departmentList', payload)
    },
    dataR(context, payload) {
        context.commit('dataR', payload)
    },
    hrModule(context, payload) {
        context.commit('hrModule', payload)
    },
    itemsCommon(context, payload) {
        context.commit('itemsCommon', payload)
    },
    accountCommon(context, payload) {
        context.commit('accountCommon', payload)
    },
    locationCommon(context, payload) {
        context.commit('locationCommon', payload)
    },
    employeeSearch(context, payload) {
        context.commit('employeeSearch', payload)
    },
    projectFinanceCommon(context, payload) {
        context.commit('projectFinanceCommon', payload)

    },
    roleRightCommon(context, payload) {
        context.commit('roleRightCommon', payload)
    },
    projectCommon(context, payload) {
        context.commit('projectCommon', payload)
    },
    procurementCommon(context, payload) {
        context.commit('procurementCommon', payload)
    },
    workCommon(context, payload) {
        context.commit('workCommon', payload)
    },
    opaSearch(context, payload) {
        context.commit('opaSearch', payload)
    },
    orderSearch(context, payload) {
        context.commit('orderSearch', payload)
    },
    receiveSearch(context, payload) {
        context.commit('receiveSearch', payload)
    },
    invoiceSearch(context, payload) {
        context.commit('invoiceSearch', payload)
    },
    lookupCommon(context, payload) {
        context.commit('lookupCommon', payload)
    },
    warehouseCommon(context, payload) {
        context.commit('warehouseCommon', payload)
    },

    storeCommon(context, payload) {
        context.commit('storeCommon', payload)
    },
    systemCommon(context, payload) {
        context.commit('systemCommon', payload)
    },

    organizationCommon(context, payload) {
        context.commit('organizationCommon', payload)
    },
}
export default actions