<template>
    <div class="cmggg  closi c6pkz  border-t-2" style="position: absolute">
        <div class="cy4yd cdqc7 cthux">
            <div class="cyrt0 cjzgx c7kk7 cpp0n">

                <!-- Site branding -->
                <!--<div class="cl0mz clmsk">-->
                    <!--&lt;!&ndash; Logo &ndash;&gt;-->
                    <!--<a class="block" href="/" aria-label="Iraji">-->
                        <!--<img alt="logo image" src="../../public/images/logo.jpeg" class="w-16"       >-->
                        <!--&lt;!&ndash;<svg class="text-purple-600 c05hy cjxdj cndmn" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
                            <!--&lt;!&ndash;<path d="M31.952 14.751a260.51 260.51 0 00-4.359-4.407C23.932 6.734 20.16 3.182 16.171 0c1.634.017 3.21.28 4.692.751 3.487 3.114 6.846 6.398 10.163 9.737.493 1.346.811 2.776.926 4.262zm-1.388 7.883c-2.496-2.597-5.051-5.12-7.737-7.471-3.706-3.246-10.693-9.81-15.736-7.418-4.552 2.158-4.717 10.543-4.96 16.238A15.926 15.926 0 010 16C0 9.799 3.528 4.421 8.686 1.766c1.82.593 3.593 1.675 5.038 2.587 6.569 4.14 12.29 9.71 17.792 15.57-.237.94-.557 1.846-.952 2.711zm-4.505 5.81a56.161 56.161 0 00-1.007-.823c-2.574-2.054-6.087-4.805-9.394-4.044-3.022.695-4.264 4.267-4.97 7.52a15.945 15.945 0 01-3.665-1.85c.366-3.242.89-6.675 2.405-9.364 2.315-4.107 6.287-3.072 9.613-1.132 3.36 1.96 6.417 4.572 9.313 7.417a16.097 16.097 0 01-2.295 2.275z"></path>&ndash;&gt;-->
                        <!--&lt;!&ndash;</svg>&ndash;&gt;-->
                    <!--</a>-->
                <!--</div>-->

                <!-- Desktop navigation -->
                <nav class="c3yx4 c37gt cajf8 mb-3">

                    <!-- Desktop menu links -->
                    <ul class="cjzgx  cnixw c7kk7 cbogo text-black">
                        <li>
                            <a class="text-black clf46 cfqfe cjzgx ck9ri c5x2a cnl4h c7kk7 cthux cpsc2" href="/">Home</a>
                        </li>
                        <li>
                            <a class="text-black clf46 cfqfe cjzgx ck9ri c5x2a cnl4h c7kk7 cthux cpsc2" href="/#">About Us</a>
                        </li>
                        <li>
                            <a class="text-black clf46 cfqfe cjzgx ck9ri c5x2a cnl4h c7kk7 cthux cpsc2" href="/#">Find A Doctor</a>
                        </li>
                        <li>
                            <a class="text-black clf46 cfqfe cjzgx ck9ri c5x2a cnl4h c7kk7 cthux cpsc2" href="/#">Ous Services</a>
                        </li>
                        <li>
                            <a class="text-black clf46 cfqfe cjzgx ck9ri c5x2a cnl4h c7kk7 cthux cpsc2" href="/#">Patient Care</a>
                        </li>
                        <li>
                            <a class="text-black clf46 cfqfe cjzgx ck9ri c5x2a cnl4h c7kk7 cthux cpsc2" href="/#">Contact us</a>
                        </li>
                        <!-- 1st level: hover -->
                        <!--<li class="co6cc" x-data="{ open: false }" @mouseenter="open = true" @mouseleave="open = false">-->
                            <!--&lt;!&ndash;:aria-expanded="open" @focus="open = true" @focusout="open = false" @click.prevent="" &ndash;&gt;-->
                            <!--<a class="clf46 cfqfe cjzgx ck9ri c5x2a cnl4h c7kk7 cthux cpsc2" href="#0"-->
                                  <!--@focus="open = true" @focusout="open = false" @click.prevent=""-->
                               <!--aria-haspopup="true" aria-expanded="false">-->
                                <!--Support-->
                                <!--<svg class="ckp7j ckj4z c05hy cl0mz cgixr czz2r cfzk1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">-->
                                    <!--<path d="M10.28 4.305L5.989 8.598 1.695 4.305A1 1 0 00.28 5.72l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z"></path>-->
                                <!--</svg>-->
                            <!--</a>-->
                            <!--&lt;!&ndash; 2nd level: hover &ndash;&gt;-->
                            <!--<ul v-if="open" class="csak4 cb5mf caaxq cmggg csl2u cqp5f cia5e cj7sj cpsc2"  x-transition:enter="cnl4h cnfmf cxu5k c9l4r" x-transition:enter-start="cpgad cn6du" x-transition:enter-end="cxj9i cg0aw" x-transition:leave="cnl4h cnfmf cxu5k" x-transition:leave-start="cxj9i" x-transition:leave-end="cpgad"  >-->
                                <!--<li>-->
                                    <!--<a class="cmrpn cckyq c38q6 cun60 c4yu6 c7kk7 cthux cpsc2" href="/constact-us" @focus="open = true" @focusout="open = false">Contact us</a>-->
                                <!--</li>-->
                                <!--<li>-->
                                    <!--<a class="cmrpn cckyq c38q6 cun60 c4yu6 c7kk7 cthux cpsc2" href="/help" @focus="open = true" @focusout="open = false">Help center</a>-->
                                <!--</li>-->
                                <!--&lt;!&ndash;<li>&ndash;&gt;-->
                                    <!--&lt;!&ndash;<a class="cmrpn cckyq c38q6 cun60 c4yu6 c7kk7 cthux cpsc2" href="404.html" @focus="open = true" @focusout="open = false">404</a>&ndash;&gt;-->
                                <!--&lt;!&ndash;</li>&ndash;&gt;-->
                            <!--</ul>-->
                        <!--</li>-->
                    </ul>

                    <!-- Desktop sign in links -->
                    <ul class="cjzgx cwlja cnixw c7kk7 cbogo" style="display: none">
                        <li>
                            <a class="text-purple-600 clf46 cjzgx ck9ri cun60 c5x2a cnl4h c7kk7 cthux cbrxr" href="/signin">Sign in</a>
                        </li>
                        <li>
                            <a class="c54ko c11jq cvikk cf0pa c0vyy" href="/signup">Sign up</a>
                        </li>
                    </ul>

                </nav>

                <!-- Mobile menu -->
                <div class="c1rkr" x-data="{ expanded: false }">

                    <!-- Hamburger button -->
                    <!--:aria-expanded="expanded"-->
                    <button class="c92w1" :class="{ 'active': expanded }" @click.stop="expanded = !expanded" aria-controls="mobile-nav"  aria-expanded="false">
                        <span class="czdh4">Menu</span>
                        <svg class="clf46 cfqfe c05hy ck9ri c5x2a cnl4h cmwav czrdw" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <rect y="4" width="24" height="2" rx="1"></rect>
                            <rect y="11" width="24" height="2" rx="1"></rect>
                            <rect y="18" width="24" height="2" rx="1"></rect>
                        </svg>
                    </button>

                    <!-- Mobile navigation -->
                    <!--@click.outside="expanded = false" @keydown.escape.window="expanded = false"-->
                    <!--$refs.mobileNav.scrollHeight-->
                    <nav id="mobile-nav" @click.outside="expanded = false" @keydown.escape.window="expanded = false"
                         class="czkkf cky15 c3woj c5x2a cmggg csl2u cd60p cdc96 closi cv00u cthux"
                         x-ref="mobileNav" :style="expanded ? 'max-height: ' + 500 + 'px; opacity: 1' : 'max-height: 0; opacity: .8'">
                        <ul class="cb5mf cthux cpsc2">
                            <li>
                                <a class="clf46 cfqfe c7kk7 cpsc2" href="features.html">Features</a>
                            </li>
                            <li>
                                <a class="clf46 cfqfe c7kk7 cpsc2" href="pricing.html">Pricing</a>
                            </li>
                            <li>
                                <a class="clf46 cfqfe c7kk7 cpsc2" href="blog.html">Blog</a>
                            </li>
                            <li>
                                <a class="clf46 cfqfe c7kk7 cpsc2" href="about.html">About us</a>
                            </li>
                            <li class="cek9b czlcq cuo2z cyjpj cpsc2">
                                <span class="cfqfe c7kk7 cpsc2">Support</span>
                                <ul class="ctb30">
                                    <li>
                                        <a class="clf46 c38q6 cun60 c4yu6 c7kk7 cpsc2" href="contact.html">Contact us</a>
                                    </li>
                                    <li>
                                        <a class="clf46 c38q6 cun60 c4yu6 c7kk7 cpsc2" href="help.html">Help center</a>
                                    </li>
                                    <li>
                                        <a class="clf46 c38q6 cun60 c4yu6 c7kk7 cpsc2" href="404.html">404</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a class="text-purple-600 clf46 c8v9b cun60 closi c7kk7 cpsc2" href="signin.html">Sign in</a>
                            </li>
                            <li>
                                <a class="c54ko cjl9b c8v9b c11jq cjzgx ck9ri c5agd cun60 c5x2a caaxq cvikk cnl4h closi cih3c cyjpj cthux cpsc2" href="signup.html">Sign up</a>
                            </li>
                        </ul>
                    </nav>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Header',
        data: () => ({
            timestamp: "",
            open:false,
            scrollHeight:50,
            expanded:false,
            profileShow:false,
            sideBar:true
        }),
        created() {
            setInterval(this.getNow, 1000);
            window.addEventListener("click", this.close);
        },
        methods: {
            getNow: function() {
                const today = new Date();
                const date = today.getDate()+'-'+ (today.toLocaleString('default', {month: 'short'})) +'-'+today.getFullYear();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.timestamp = dateTime;
            },
            LoginClick(){
                this.$emit('clicked',false)
            },
            profile() {
                this.profileShow = !this.profileShow;
            },
            close(e) {
                if (!this.$el.contains(e.target)) {
                    this.profileShow = false;
                }},
            sidermenu(){
                this.$emit('sidermenu',this.sideBar = !this.sideBar)
            },
            async logOut () {
//                await apiCall.postMethod('/logout',{}).then((response) => {
//                    if(response){
//                        this.$store.dispatch('omsTokenRz', '');
//                        this.$router.go('/');
//                        console.log('ddd')
//                    }
//                }).catch(error=>{
//                    if(error.response.status==401){
//                        this.$store.dispatch('omsTokenRz', '');
//                        this.$router.go('/')
//                    }
//                })


            }
        }
    }
</script>