<template>
    <div class=" relative mt-16 mb-10">

        <!--<Carousel v-bind="settings" :breakpoints="breakpoints">-->
            <!--<Slide v-for="slide in 10" :key="slide">-->
                <!--<div class="carousel__item">{{ slide }}</div>-->
            <!--</Slide>-->

            <!--<template #addons>-->
                <!--<Navigation />-->
            <!--</template>-->
        <!--</Carousel>-->

        <Carousel :autoplay="2000" :wrapAround="true"  v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(slide, index) in sliders" :key="index">
                <div class="carousel__item">
                    <a href="#" class=" border-b-2 pt-2 pb-4" style="text-align: -webkit-center">
                        <img class="rounded-t-lg"  src="./../../public/doctors/download.png" alt="Skyscrapers" />
                    </a>
                    <div class="p-4">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                        {{ slide.doctor_name }}
                    </h5>
                    <h6 class="mb-2 text-sm font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                        {{ slide.specialist }}
                    </h6>
                    <p>
                        {{ sliders[index].designation_name }},
                        {{ sliders[index].department_name }}
                    </p>
                    <p v-for="(i) in sliders[index].schedules" :key="i.doctor_id">
                        <template v-if="i.start_time && i.end_time">
                        <span class="font-bold">{{i.schedule_day}}: </span>
                        <span>{{i.start_time}} - </span>
                        <span>{{i.end_time}}</span>
                        </template>
                    </p>
                    </div>
                    <div class="mt-auto border-t-2 border-neutral-100 px-6 py-3 text-left dark:border-neutral-600 dark:text-neutral-50 text-black">
                        <div class="flex flex-row">
                            <svg class="h-4 w-4 mt-1 align-middle fill-stroke text-blue-500 hover:text-blue-600" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                            <span class="ml-3">
                            +880-01912-545454
                            </span>
                        </div>
                        <div class="flex flex-row">
                            <svg class="h-4 w-4 align-middle mt-1 fill-stroke text-blue-500 hover:text-blue-600" width="12" height="12" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"></path>  <rect x="3" y="5" width="18" height="14" rx="2"></rect>  <polyline points="3 7 12 13 21 7"></polyline></svg>
                            <span class="ml-3">appointment@iraji.com.bd</span>
                        </div>
                    </div>
                </div>
            </Slide>

            <template #addons>
                <Navigation />
            </template>
        </Carousel>

        <!--<Carousel :autoplay="2000" :wrap-around="true">-->
            <!--<Slide  >-->
                <!--<div v-for="(item) in sliders" :key="item.id" class="mx-3 mt-6 flex flex-col text-center rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0">-->
                    <!--<a href="#" class=" border-b-2 pt-2 pb-4" style="text-align: -webkit-center">-->
                        <!--<img class="rounded-t-lg"  src="./../../public/doctors/download.png" alt="Skyscrapers" />-->
                        <!--&lt;!&ndash;<img class="rounded-t-lg"  src="https://tecdn.b-cdn.net/img/new/standard/city/044.webp" alt="Skyscrapers" />&ndash;&gt;-->
                    <!--</a>-->
                    <!--<div class="p-4">-->
                        <!--<h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">-->
                            <!--{{ item.doctor_name }}-->
                        <!--</h5>-->
                        <!--<h6 class="mb-2 text-sm font-medium leading-tight text-neutral-800 dark:text-neutral-50">-->
                            <!--{{ item.dept_name }}-->
                        <!--</h6>-->
                        <!--<p v-for="(i) in item.schedules" :key="i.doctor_id">-->
                            <!--<template v-if="i.start_time && i.end_time">-->
                                <!--<span class="font-bold">{{i.schedule_day}}: </span>-->
                                <!--<span>{{i.start_time}} - </span>-->
                                <!--<span>{{i.end_time}}</span>-->
                            <!--</template>-->
                        <!--</p>-->
                        <!--&lt;!&ndash;<p class="text-left text-justify text-neutral-600 dark:text-neutral-200">&ndash;&gt;-->
                        <!--&lt;!&ndash;This is a wider card with supporting text below as a natural&ndash;&gt;-->
                        <!--&lt;!&ndash;lead-in to additional content. This content is a little bit&ndash;&gt;-->
                        <!--&lt;!&ndash;longer.&ndash;&gt;-->
                        <!--&lt;!&ndash;</p>&ndash;&gt;-->
                    <!--</div>-->
                    <!--<div class="mt-auto border-t-2 border-neutral-100 px-6 py-3 text-left dark:border-neutral-600 dark:text-neutral-50 text-black">-->
                        <!--<div class="flex flex-row">-->
                            <!--<svg class="h-4 w-4 mt-1 align-middle fill-stroke text-blue-500 hover:text-blue-600" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>-->
                            <!--<span class="ml-3">-->
                      <!--+880-01912-545454-->
                    <!--</span>-->
                        <!--</div>-->
                        <!--<div class="flex flex-row">-->
                            <!--<svg class="h-4 w-4 align-middle mt-1 fill-stroke text-blue-500 hover:text-blue-600" width="12" height="12" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"></path>  <rect x="3" y="5" width="18" height="14" rx="2"></rect>  <polyline points="3 7 12 13 21 7"></polyline></svg>-->
                            <!--<span class="ml-3">appointment@iraji.com.bd</span>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</Slide>-->

            <!--<template #addons>-->
                <!--<Pagination />-->
            <!--</template>-->
        <!--</Carousel>-->
    </div>
</template>

<script>
    // import Carousel from 'vue-owl-carousel'
    // import VueSlickCarousel from 'vue-slick-carousel'
    import { defineComponent } from 'vue'
    import { Carousel, Slide, Navigation } from 'vue3-carousel'
    // import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    // import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    // import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    // import {initTE} from "tw-elements";
    import 'vue3-carousel/dist/carousel.css'

    export default defineComponent({
        props:['sliders'],
        components:{ Carousel,
            Slide,
            // Pagination,
            Navigation
        },
        name: 'Slider',
        data: () => ({
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3.5,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
            },
            // settings3: {
            //     dots: true,
            //     infinite: true,
            //     rows: 5,
            //     initialSlide: 2,
            //     speed: 500,
            //     slidesToShow: 2,
            //     slidesToScroll: 1,
            //     swipeToSlide: true,
            //     arrows: true,
            //     prevArrow: true,
            //     nextArrow: true,
            // },

            // timestamp: "",
            // open:false,
            // scrollHeight:50,
            // expanded:false,
            // profileShow:false,
            // sideBar:true
        }),
        created() {

        },
        methods: {

        },
        mounted() {

        }
    });
</script>