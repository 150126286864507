import axios from 'axios'


// const baseURL = 'http://127.0.0.1:8000/api/web'; // local server
// const baseURL = 'http://iraji.local/api/web'; // local server
const baseURL = 'http://iraji.com.bd:82/api/web';


// export const URL = 'http://iraji.local/'; // local server
const URL = 'http://iraji.com.bd:82/';

import store from "../store/store"
import router from '../router'

export default {
    async execute(method, uri, data, params = {}) {
        const token = sessionStorage.getItem('demKey');
        const accessToken = token ? token : store.state.hitTokenRz;

        const client = axios.create({
            baseURL: baseURL,
            json: true,
            changeOrigin: true,
            withCredentials: true
        });

        client.interceptors.response.use(response => response, error => {

            if (error.response) {
                if (error.response.status == 401) {
                    store.dispatch('hitTokenRz', '');
                    sessionStorage.removeItem('demKey')
                    router.go('/')
                }
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error response', error.response);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('error.request', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            throw error
        });

        // client.interceptors.response.use(response => response, error => errorHandler(error))

        return client({
            method,
            url: uri,
            data,
            params: params,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json'
                // 'Content-Type': 'multipart/form-data'

                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',

            }
        });
    },

    getMethod(uri, params = {}) {
        return this.execute('get', uri, {}, params);
    },

    postMethod(uri, data) {
        return this.execute('post', uri, data);
    },

    putMethod(uri, data) {
        return this.execute('put', uri, data);
    },

    deleteMethod(uri) {
        return this.execute('delete', uri);
    },
    baseURL, URL,
}