<template>
  <div class="">

      <div class="cy4yd mx-auto px-4 max-sm:px-4 bg-gray text-black pt-4 pb-5">
        <div class=" md:flex xl:flex lg:flex  items-center justify-between">
          <div class="flex text-center justify-center mb-2">
            <!--<i class="bi bi-telephone-inbound fs-2"></i>-->
            <!--<font-awesome-icon class="text-3xl mt-2" icon="fa-solid fa-user-secret" />-->
            <!--<font-awesome-icon class="text-3xl mt-2" icon="fa-solid fa&#45;&#45;envelope" />-->
            <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" class="text-3xl mt-2" viewBox="0 0 512 512">
              <path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"/></svg>
            <div class="ml-3">
              <h5 class="text-black text-left mb-0">
                 <p class="text-lg">Call Now:</p>
                <p>+88 01897-960440</p>
              </h5>
            </div>
          </div>
          <div class="  ms-3 text-center" style="text-align: -webkit-center">
            <a href="/" class="text-center  mb-0" style="text-align: -webkit-center">
              <img alt="iraji logo" src="../public/images/logo.jpeg" class="w-16">
            </a>
          </div>
          <div class="flex flex-wrap  text-center justify-center mt-2">
            <!--<i class="bi bi-envelope fs-2"></i>-->
            <!--<font-awesome-icon class="text-3xl mt-2" icon="fa-solid fa-user-secret" />-->
            <svg class="text-3xl mt-2" xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 512 512">
              <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
            <div class="ml-3">
              <h5 class="text-black mb-0">
                <p class="text-left">
                  Mail Now:
                </p>
                <p>info@iraji.com.bd</p>
              </h5>
            </div>
          </div>
        </div>
    </div>

      <Header />

      <!-- Page content -->
      <main class="cbogo">
        <router-view/>
      </main>

      <!-- Site footer -->
      <Footer />

  </div>

  <!--<nav>-->
    <!--<router-link to="/">Home</router-link> |-->
    <!--<router-link to="/about">About</router-link>-->
  <!--</nav>-->
  <!--<router-view/>-->

</template>

<script>
    // @ is an alias to /src
    import Header from '@/components/header.vue'
    import Footer from '@/components/footer.vue'
    import Slider from '@/components/slider.vue'
    // import {Carousel, initTE} from "tw-elements";

    export default {
        name: 'App',
        components: {
            Header, Footer, Slider
        },
        data: () => ({
            tab: false,
            value: false,
            sidevalue: true,
            token: '',
            logout: 0,
            server: false,
            errors:{},
            formData:{},
        }),
        created(){
            // initTE({ Carousel });
        },
        mounted() {
            // initTE({ Carousel });
        }
    }


</script>

<style>
/*#app {*/
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
/*}*/

/*nav {*/
  /*padding: 30px;*/
/*}*/

/*nav a {*/
  /*font-weight: bold;*/
  /*color: #2c3e50;*/
/*}*/

/*nav a.router-link-exact-active {*/
  /*color: #42b983;*/
/*}*/
</style>
