

import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate';

import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import custom from './custom'

export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    custom,
    // plugins: [createPersistedState()]
})

// import { createStore } from 'vuex'
//
// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
