const state = {
    omsTokenRz: '',
    RoleRights: [],
    projectPerimission: [],
    projectGlobal: [],
    projectRight: [],
    project_menu: [],
    userInfo: [],
    Menus: [],
    departmentList: [{
            textName: 'ID',

            condition: ''
        },
        {
            textName: 'Name',

            condition: ''
        }, {
            textName: 'Location',

            condition: ''
        },
        {
            textName: 'Status',

            condition: ''
        },
        {
            textName: 'Remarks',

            condition: 'text'
        },
    ],
    hrModule: [],
    accountCommon: [],
    itemsCommon: [],
    employeeSearch: [],
    projectFinanceCommon: [],
    roleRightCommon: [],
    dataR: [],
    projectCommon: [],
    procurementCommon: [],
    workCommon: [],
    opaSearch: [],
    orderSearch: [],
    invoiceSearch: [],
    receiveSearch: [],
    lookupCommon: [],
    warehouseCommon: [],
    storeCommon: [],
    systemCommon: [],
    organizationCommon: [],
    locationCommon: []
}
export default state