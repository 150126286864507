const getters = {
    isLoggedIn: state => !!state.token,
    static: state => state.static,
    role_right: state => (right_id) => {
        var uId = state.userInfo ? state.userInfo.id : null;
        if (uId == '62' || uId == '128') {
            return true;
        } else {
            return state.RoleRights.find(d => d == right_id);
        }

    },

    project_role_right_con: state => (projectArray, right_id) => {

        var uId = state.userInfo ? state.userInfo.id : null;
        if (uId == '62' || uId == '128') {
            return true;
        } else {
            if (state.projectGlobal.length > 0) {
                return true;
            } else {
                if (projectArray.length > 0) {
                    var editProject = projectArray.map(function(e) { return e.toString() });
                    var project = state.projectRight.filter(d => {
                        return d.right_id == right_id;
                    }).map(function(item) {
                        return item.project_id;
                    });
                    console.log(editProject)
                    return project.find(element => editProject.includes(element));
                } else {
                    return false
                }

            }
        }
    },
    // project_role_right_check: state => (projectArray, rightArray) => {

    //     var uId = state.userInfo ? state.userInfo.id : null;
    //     if (uId == '62' || uId == '128') {
    //         return true;
    //     } else {
    //         if (state.projectGlobal.length > 0) {
    //             return true;
    //         } else {
    //             var editProject = projectArray;

    //             var project = state.projectRight.filter(element => editProject.includes(element.project_id)).map(function(item) {
    //                 return item.right_id;
    //             });


    //             var project = rightArray
    //             return project.find(element => editProject.includes(element));
    //         }
    //     }
    // },




    menus_name: state => (menu_id) => {
        var uId = state.userInfo ? state.userInfo.id : null;
        if (uId == '62' || uId == '128') {
            return true;
        } else {
            return state.Menus.find(d => d == menu_id);
        }

    },

    // // Procurement Page Show 
    // tab_show: state => (show_id) => {
    //     var showId = state.projectGlobal ? state.projectGlobal.id : null;
    //     if (showId == '62' || showId == '128') {
    //         return true;
    //     } else {
    //         return state.Menus.find(d => d == show_id);
    //     }

    // },


    project_role_right: state => (project_id, right_id) => {
        var uId = state.userInfo ? state.userInfo.id : null;
        if (uId == '62' || uId == '128') {
            return true;
        } else {
            if (state.projectGlobal.length > 0) {
                if (project_id) {
                    // console.log('Right Global Invividual', project_id, 'Right', right_id)
                    return state.projectRight.find(d => (d.project_id == '0' || d.project_id == project_id) && d.right_id == right_id);
                } else {
                    // console.log('right Global')
                    return state.projectRight.find(d => d.project_id == '0' && d.right_id == right_id);
                }

            } else {
                return state.projectRight.find(d => d.project_id == project_id && d.right_id == right_id);
            }
        }

    },
    project_role_right_new: state => (project_id, right_id) => {
        var uId = state.userInfo ? state.userInfo.id : null;
        if (uId == '62' || uId == '128') {
            return true;
        } else {
            if (state.projectGlobal.length > 0) {
                // return true;
                return state.projectRight.find(d => d.project_id == '0' && d.right_id == right_id);
            } else {
                return state.projectRight.find(d => d.project_id == project_id && d.right_id == right_id);
            }
        }

    },
    project_role_menu: state => (project_id, menu_id) => {
        var uId = state.userInfo ? state.userInfo.id : null;
        if (uId == '62' || uId == '128') {
            return true;
        } else {
            if (state.projectGlobal.length > 0) {

                if (project_id) {
                    // console.log('menu Global Invividual', project_id)
                    return state.project_menu.find(d => (d.project_id == '0' || d.project_id == project_id) && d.module_id == menu_id);
                } else {
                    // console.log('menu Global')
                    return state.project_menu.find(d => d.project_id == '0' && d.module_id == menu_id);
                }

            } else {
                // console.log('menu Invividual')
                return state.project_menu.find(d => d.project_id == project_id && d.module_id == menu_id);
            }
        }

    },
    projectpermission: state => () => {

        return state.projectpermission;
    },

    get_project_ids: state => () => {

        if (state.projectGlobal.length > 0) {
            return [];
        } else {
            return Object.values(state.projectPerimission);
        }
    },

    worklist_role: state => (project_id) => {

        var uId = state.userInfo ? state.userInfo.id : null;
        if (uId == '62' || uId == '128') {
            return true;
        } else {
            if (state.projectGlobal.length > 0) {
                return true;
            } else {
                var editRole = ['350', '261', '292', '285', '289', '358', '337', '364', '301', '367', '303', '305', '332', '357', '351'];
                var role = state.projectRight.filter(d => {
                    return d.project_id == project_id;
                }).map(function(item) {
                    return item.right_id;
                });
                // var role = []
                return role.find(element => editRole.includes(element));
            }
        }
    },
    project_role_right_bulk: state => (project_id) => {
        var uId = state.userInfo ? state.userInfo.id : null;
        if (uId == '62' || uId == '128') {
            return true;
        } else {
            if (state.projectGlobal.length > 0) {
                if (project_id) {
                    // console.log('Right Global Invividual', project_id, 'Right', right_id)
                    return state.projectRight.find(d => (d.project_id == '0' || d.project_id == project_id));
                } else {
                    // console.log('right Global')
                    return state.projectRight.find(d => d.project_id == '0');
                }

            } else {
                return state.projectRight.find(d => d.project_id == project_id);
            }
        }

    }

}
export default getters