import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import './inputTailwind.css'
import '../public/style.css'
import '../public/outputTailwind.css'
import "vue-select/dist/vue-select.css"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import vSelect from 'vue-select'

/* add icons to the library */
library.add(faUserSecret);

// createApp(App).use(store).use(router).mount('#app');
const app = createApp(App);
// app.config.errorHandler = () => null;
// app.config.warnHandler = () => null;
app.use(router, Vuex)
    // .use(Notifications)
    .use(store);
    // .use(excel);
// global registration - can be used anywhere
app.component("vSelect", vSelect);
app.component('font-awesome-icon', FontAwesomeIcon);
//     .component("Loader", Loader)
//     .component("DeleteModal", DeleteModal)
//     .component("Paginate", Paginate)
//     .component("Popup", Popup);

app.mount("#app");