const mutations = {
    mutedLoad(state, payload) {
        state.departmentList = Object.assign(state.departmentList, payload)
    },
    dataR(state, data) {
        state.dataR = data
    },
    userInfo(state, data) {
        state.userInfo = data
    },
    omsTokenRz(state, data) {
        state.omsTokenRz = data
    },
    RoleRights(state, data) {
        state.RoleRights = data
    },
    projectRight(state, data) {
        state.projectRight = data
    },
    project_menu(state, data) {
        state.project_menu = data
    },
    Menus(state, data) {
        state.Menus = data
    },
    projectPerimission(state, data) {
        state.projectPerimission = data
    },
    projectGlobal(state, data) {
        state.projectGlobal = data
    },
    hrModule(state, data) {
        state.hrModule = data
    },
    accountCommon(state, data) {
        state.accountCommon = data
    },
    itemsCommon(state, data) {
        state.itemsCommon = data
    },
    locationCommon(state, data) {
        state.locationCommon = data
    },
    employeeSearch(state, data) {
        state.employeeSearch = data
    },
    projectFinanceCommon(state, data) {
        state.projectFinanceCommon = data
    },
    roleRightCommon(state, data) {
        state.roleRightCommon = data
    },
    projectCommon(state, data) {
        state.projectCommon = data
    },
    procurementCommon(state, data) {
        state.procurementCommon = data
    },
    workCommon(state, data) {
        state.workCommon = data
    },
    opaSearch(state, data) {
        state.opaSearch = data
    },
    orderSearch(state, data) {
        state.orderSearch = data
    },
    receiveSearch(state, data) {
        state.receiveSearch = data
    },
    invoiceSearch(state, data) {
        state.invoiceSearch = data
    },
    lookupCommon(state, data) {
        state.lookupCommon = data
    },
    warehouseCommon(state, data) {
        state.warehouseCommon = data
    },
    storeCommon(state, data) {
        state.storeCommon = data
    },
    systemCommon(state, data) {
        state.systemCommon = data
    },
    organizationCommon(state, data) {
        state.organizationCommon = data
    },

}
export default mutations