<template>
    <div>
        <div class="c58dn pt-4">
            <div class="cy4yd cd60p cdqc7 cthux">

                <!-- Top area: Blocks -->
                <div class="cesug cpvc9 c4py0 clxja c6m26 cfsmm">

                    <!-- 1st block -->
                    <div class="cftdb c1z2w">
                        <div class="cpiof">
                            <!-- Logo -->
                            <a class="cgvj5" href="/" aria-label="Cruip">
                                <img alt="iraji logo" src="../../public/images/logo.png" class="w-16">
                                <!--<svg class="text-purple-600 c05hy cjxdj cndmn" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">-->
                                    <!--<path d="M31.952 14.751a260.51 260.51 0 00-4.359-4.407C23.932 6.734 20.16 3.182 16.171 0c1.634.017 3.21.28 4.692.751 3.487 3.114 6.846 6.398 10.163 9.737.493 1.346.811 2.776.926 4.262zm-1.388 7.883c-2.496-2.597-5.051-5.12-7.737-7.471-3.706-3.246-10.693-9.81-15.736-7.418-4.552 2.158-4.717 10.543-4.96 16.238A15.926 15.926 0 010 16C0 9.799 3.528 4.421 8.686 1.766c1.82.593 3.593 1.675 5.038 2.587 6.569 4.14 12.29 9.71 17.792 15.57-.237.94-.557 1.846-.952 2.711zm-4.505 5.81a56.161 56.161 0 00-1.007-.823c-2.574-2.054-6.087-4.805-9.394-4.044-3.022.695-4.264 4.267-4.97 7.52a15.945 15.945 0 01-3.665-1.85c.366-3.242.89-6.675 2.405-9.364 2.315-4.107 6.287-3.072 9.613-1.132 3.36 1.96 6.417 4.572 9.313 7.417a16.097 16.097 0 01-2.295 2.275z"></path>-->
                                <!--</svg>-->
                            </a>
                        </div>
                        <!--<div class="c38q6">-->
                            <!--To serve the humanity as a whole with this noble vision. The IRAJI has agreed upon to provide health care service to the-->
                            <!--people of Bangladesh with affordable cost.-->
                        <!--</div>-->
                        <div class="c38q6">
                            <p class="flex items-center mb-2 mt-2">
                                <!--<svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">&lt;!&ndash;!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.&ndash;&gt;<path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>-->
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#f6f4f4" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                                <span class="ml-2">
                                    Lubna Trade Centre, Hasnabad, South Keraniganj,
                                    Dhaka-1311, Bangladesh
                                </span>
                            </p>
                            <p class="flex items-center mb-2 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffffff" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                                <span class="ml-2">
                                    +88 01676 929565
                                </span>
                            </p>
                            <p class="flex items-center mb-2 mt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffffff" d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
                                <span class="ml-2">
                                    info@iraji.com.bd
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- 2nd, 3rd and c5zsp  4th blocks -->
                    <div class="cbvww ccm6i ccwu8 clxja cfsmm">

                        <!-- 2nd block -->
                        <!--<div class="c4yu6">-->
                            <!--<h6 class="cwgde cun60 cbrs9">Products</h6>-->
                            <!--<ul>-->
                                <!--<li class="cbrs9">-->
                                    <!--<a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Web Studio</a>-->
                                <!--</li>-->
                                <!--<li class="cbrs9">-->
                                    <!--<a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">DynamicBox Flex</a>-->
                                <!--</li>-->
                                <!--<li class="cbrs9">-->
                                    <!--<a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Programming Forms</a>-->
                                <!--</li>-->
                            <!--</ul>-->
                        <!--</div>-->

                        <!-- 3rd block -->
                        <div class="c4yu6">
                            <h6 class="cwgde cun60 cbrs9 text-lg border-b-2">Laboratory Services</h6>
                            <ul>
                                <li class="cbrs9">
                                    <a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Pathology Testing</a>
                                </li>
                                <li class="cbrs9">
                                    <a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Microbiology Tests</a>
                                </li>
                                <li class="cbrs9">
                                    <a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Biochemistry Tests</a>
                                </li>
                                <li class="cbrs9">
                                    <a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Histopatology Tests</a>
                                </li>
                                <li class="cbrs9">
                                    <a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Urine Tests</a>
                                </li>
                                <li class="cbrs9">
                                    <a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Blood Tests</a>
                                </li>
                            </ul>
                        </div>

                        <!-- 4th block -->
                        <div class="c4yu6">
                            <h6 class="cwgde cun60 cbrs9 text-lg border-b-2">Services</h6>
                            <ul>
                                <!--<li class="cbrs9">-->
                                    <!--<a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Radiology & Imaging Services</a>-->
                                <!--</li>-->
                                <li class="cbrs9">
                                    <a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Diabetes Clinic</a>
                                </li>
                                <!--<li class="cbrs9">-->
                                    <!--<a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Pain and Physiotherapy Center</a>-->
                                <!--</li>-->
                                <!--<li class="cbrs9">-->
                                    <!--<a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Dental Clinic</a>-->
                                <!--</li>-->
                                <li class="cbrs9">
                                    <a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Gynaecological Clinic</a>
                                </li>
                                <li class="cbrs9">
                                    <a class="cjryw c38q6 ck9ri c5x2a cnl4h" href="#0">Cardiac Clinic</a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>

                <!-- Bottom area -->
                <div class="crdpj cq6wr c3yx4">

                    <!-- Social links -->
                    <ul class="caofi cyhlj cc9mh ctyga c7kk7">
                        <li>
                            <a class="text-purple-600 czfu8 cjryw c8v9b cjzgx chaj8 ck9ri cb5mf c5x2a cnl4h c7kk7" href="#0" aria-label="Twitter">
                                <svg class="c05hy cjxdj cndmn" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z"></path>
                                </svg>
                            </a>
                        </li>
                        <li class="cia5e">
                            <a class="text-purple-600 czfu8 cjryw c8v9b cjzgx chaj8 ck9ri cb5mf c5x2a cnl4h c7kk7" href="#0" aria-label="Github">
                                <svg class="c05hy cjxdj cndmn" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z"></path>
                                </svg>
                            </a>
                        </li>
                        <li class="cia5e">
                            <a class="text-purple-600 czfu8 cjryw c8v9b cjzgx chaj8 ck9ri cb5mf c5x2a cnl4h c7kk7" href="#0" aria-label="Facebook">
                                <svg class="c05hy cjxdj cndmn" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z"></path>
                                </svg>
                            </a>
                        </li>
                        <li class="cia5e">
                            <a class="text-purple-600 czfu8 cjryw c8v9b cjzgx chaj8 ck9ri cb5mf c5x2a cnl4h c7kk7" href="#0" aria-label="Instagram">
                                <svg class="c05hy cjxdj cndmn" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20.145" cy="11.892" r="1"></circle>
                                    <path d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"></path>
                                    <path d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z"></path>
                                </svg>
                            </a>
                        </li>
                        <li class="cia5e">
                            <a class="text-purple-600 czfu8 cjryw c8v9b cjzgx chaj8 ck9ri cb5mf c5x2a cnl4h c7kk7" href="#0" aria-label="Linkedin">
                                <svg class="c05hy cjxdj cndmn" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                    <!-- Copyrights note -->
                    <div class="c38q6 c4yu6 clmsk"> © ait.com.bd All rights reserved.</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script >
    //    import apiCall from '../../common/config'
    export default {
        name: 'Footer',
        data: () => ({
            timestamp: "",
            profileShow:false,
            sideBar:true
        }),
        created() {
            setInterval(this.getNow, 1000);
            window.addEventListener("click", this.close);
        },
        methods: {


        },
    }
</script>